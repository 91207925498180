var render = function () {
  var _vm$role, _vm$role2, _vm$role3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    staticClass: "header",
    attrs: {
      "bg-color": "white",
      "w": "100%",
      "px": ['20px', '30px'],
      "py": ['17px', '10px'],
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "position": "sticky",
      "top": "0",
      "z-index": "11"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "max-width": (_vm$role = _vm.role) !== null && _vm$role !== void 0 && _vm$role.includes('admin') ? '1700px' : '1270px',
      "justify-content": "space-between",
      "align": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "d": ['block', 'none']
    }
  }, [_vm.backTitle && _vm.isAuthenticated ? _c('c-flex', [_vm.withBack ? _c('button', {
    staticClass: "flex items-center mr-2",
    on: {
      "click": _vm.handleBack
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "color": "black",
      "size": "26px"
    }
  })], 1) : _vm._e(), _c('span', {
    staticClass: "text-lg font-bold font-secondary"
  }, [_vm._v(_vm._s(_vm.backTitle))])]) : _c('c-flex', {
    attrs: {
      "gap": "8px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm.withBack ? _c('button', {
    staticClass: "flex items-center mr-2",
    on: {
      "click": _vm.handleBack
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "color": "black",
      "size": "26px"
    }
  })], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.index'
        });
      }
    }
  }, [_c('logo-colored')], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "d": ['none', 'flex'],
      "align-items": "center"
    }
  }, [_vm.isAuthenticated && _vm.sideMenu && !((_vm$role2 = _vm.role) !== null && _vm$role2 !== void 0 && _vm$role2.includes('admin')) ? _c('ButtonIcon', {
    staticClass: "p-2 mr-8",
    staticStyle: {
      "height": "auto",
      "width": "auto"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle');
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconHamburger,
      "height": "19",
      "width": "19",
      "fill": "#008C81"
    }
  })], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.index'
        });
      }
    }
  }, [_c('logo-colored')], 1)], 1), _c('c-flex', {
    attrs: {
      "align": "center",
      "display": ['none', 'flex']
    }
  }, [_vm.isAuthenticated && _vm.role === 'client' ? _c('c-list', [_c('div', {
    staticClass: "flex items-center gap-8"
  }, [_c('header-keranjang'), _c('header-notification'), _c('header-user'), _vm.showTotalPoinUser ? _c('c-box', [_c('UserPoin', {
    attrs: {
      "value": _vm.totalPoinUser
    }
  })], 1) : _vm._e()], 1)]) : _vm.isAuthenticated && _vm.role === 'nutritionist' ? _c('header-menus-nutritionist', {
    attrs: {
      "is-authenticated": _vm.isAuthenticated,
      "role": _vm.role
    }
  }) : _vm.isAuthenticated && (_vm$role3 = _vm.role) !== null && _vm$role3 !== void 0 && _vm$role3.includes('admin') ? _c('c-list', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "gap": "32px"
    }
  }, [_c('header-notification'), _c('header-user', {
    attrs: {
      "role": _vm.role,
      "mr": "unset"
    }
  })], 1) : !_vm.isAuthenticated ? _c('c-list', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "h": "auto",
      "gap": "24px"
    }
  }, [_c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-beranda')], 1), _c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-tentang-kami')], 1), _c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-program', {
    attrs: {
      "list-menu-program": _vm.listMenuProgram
    }
  })], 1), _c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-pricing')], 1), _c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-blog')], 1), _c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-store', {
    attrs: {
      "target": "_blank"
    }
  })], 1), _c('c-list-item', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('header-login')], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "md:hidden"
  }, [!_vm.isAuthenticated ? _c('HeaderHamburger', {
    attrs: {
      "list-menu-program": _vm.listMenuProgram
    }
  }) : _vm.isAuthenticated ? _c('div', {
    staticClass: "flex items-center gap-2"
  }, [_vm.notification ? _c('c-box', [_c('PopupMenuNotificationWrapper', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hasUnreadNotification = _ref.hasUnreadNotification;
        return [_c('ButtonIcon', [_c('BadgeUnread', {
          attrs: {
            "show": hasUnreadNotification
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": _vm.iconNotification,
            "height": "19",
            "width": "19",
            "fill": "#323232"
          }
        })], 1)], 1)];
      }
    }], null, false, 4114805499)
  })], 1) : _vm._e(), _vm.cart ? _c('router-link', {
    attrs: {
      "to": {
        name: 'client.cart'
      }
    }
  }, [_c('ButtonIcon', [_c('BadgeUnread', {
    attrs: {
      "show": _vm.getItemsQuantity
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCart,
      "height": "19",
      "width": "19",
      "fill": "#323232"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.showTotalPoinUser ? _c('c-box', [_c('UserPoin', {
    attrs: {
      "value": _vm.totalPoinUser
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }