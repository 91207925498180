<template>
  <c-box
    class="header"
    bg-color="white"
    w="100%"
    :px="['20px', '30px']"
    :py="['17px', '10px']"
    box-shadow="0px 5px 30px 0px #0000000D"
    position="sticky"
    top="0"
    z-index="11"
  >
    <c-flex
      w="100%"
      mx="auto"
      :max-width="role?.includes('admin') ? '1700px' : '1270px'"
      justify-content="space-between"
      align="center"
    >
      <!-- Left Header (Mobile) -->
      <c-box :d="['block', 'none']">
        <c-flex v-if="backTitle && isAuthenticated">
          <button
            v-if="withBack"
            class="flex items-center mr-2"
            @click="handleBack"
          >
            <c-icon
              name="chevron-left"
              color="black"
              size="26px"
            />
          </button>
          <span class="text-lg font-bold font-secondary">{{ backTitle }}</span>
        </c-flex>
        <c-flex
          v-else
          gap="8px"
          justify-content="center"
          align-items="center"
        >
          <button
            v-if="withBack"
            class="flex items-center mr-2"
            @click="handleBack"
          >
            <c-icon
              name="chevron-left"
              color="black"
              size="26px"
            />
          </button>
          <c-box
            cursor="pointer"
            @click="$router.push({name:'client.index'})"
          >
            <logo-colored />
          </c-box>
        </c-flex>
      </c-box>
      <!-- Left Header (Desktop) -->
      <c-box
        :d="['none', 'flex']"
        align-items="center"
      >
        <ButtonIcon
          v-if="isAuthenticated && sideMenu && !role?.includes('admin')"
          class="p-2 mr-8"
          style="height: auto; width: auto;"
          @click="$emit('toggle')"
        >
          <inline-svg
            :src="iconHamburger"
            height="19"
            width="19"
            fill="#008C81"
          />
        </ButtonIcon>
        <c-box
          cursor="pointer"
          @click="$router.push({name:'client.index'})"
        >
          <logo-colored />
        </c-box>
      </c-box>

      <!-- Right Header -->
      <c-flex
        align="center"
        :display="['none', 'flex']"
      >
        <c-list v-if="isAuthenticated && role === 'client'">
          <div class="flex items-center gap-8">
            <header-keranjang />
            <header-notification />
            <header-user />
            <c-box v-if="showTotalPoinUser">
              <UserPoin :value="totalPoinUser" />
            </c-box>
          </div>
        </c-list>
        <header-menus-nutritionist
          v-else-if="isAuthenticated && role === 'nutritionist'"
          :is-authenticated="isAuthenticated"
          :role="role"
        />
        <c-list
          v-else-if="isAuthenticated && role?.includes('admin')"
          display="flex"
          align-items="center"
          gap="32px"
        >
          <header-notification />
          <header-user
            :role="role"
            mr="unset"
          />
        </c-list>
        <c-list
          v-else-if="!isAuthenticated"
          display="flex"
          align-items="center"
          h="auto"
          gap="24px"
        >
          <c-list-item text-align="center">
            <header-beranda />
          </c-list-item>
          <c-list-item text-align="center">
            <header-tentang-kami />
          </c-list-item>
          <c-list-item text-align="center">
            <header-program :list-menu-program="listMenuProgram" />
          </c-list-item>
          <c-list-item text-align="center">
            <header-pricing />
          </c-list-item>
          <c-list-item text-align="center">
            <header-blog />
          </c-list-item>
          <c-list-item text-align="center">
            <header-store target="_blank" />
          </c-list-item>
          <c-list-item text-align="center">
            <header-login />
          </c-list-item>
          <!--          <c-list-item text-align="center">-->
          <!--            <header-register />-->
          <!--          </c-list-item>-->
        </c-list>
      </c-flex>
      <div class="md:hidden">
        <HeaderHamburger
          v-if="!isAuthenticated"
          :list-menu-program="listMenuProgram"
        />
        <div
          v-else-if="isAuthenticated"
          class="flex items-center gap-2"
        >
          <c-box v-if="notification">
            <PopupMenuNotificationWrapper v-slot="{hasUnreadNotification}">
              <ButtonIcon>
                <BadgeUnread :show="hasUnreadNotification">
                  <inline-svg
                    :src="iconNotification"
                    height="19"
                    width="19"
                    fill="#323232"
                  />
                </BadgeUnread>
              </ButtonIcon>
            </PopupMenuNotificationWrapper>
          </c-box>
          <router-link
            v-if="cart"
            :to="{name: 'client.cart'}"
          >
            <ButtonIcon>
              <BadgeUnread :show="getItemsQuantity">
                <inline-svg
                  :src="iconCart"
                  height="19"
                  width="19"
                  fill="#323232"
                />
              </BadgeUnread>
            </ButtonIcon>
          </router-link>
          <c-box v-if="showTotalPoinUser">
            <UserPoin :value="totalPoinUser" />
          </c-box>
        </div>
      </div>
    </c-flex>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import iconCart from '@/assets/icons/icon-cart.svg'
import iconHamburger from '@/assets/icon-hamburger.svg'
import iconNotification from '@/assets/icons/icon-notification.svg'
import LogoColored from '../logo-colored.vue'
import HeaderKeranjang from './header-keranjang.vue'
import HeaderNotification from './header-notification.vue'
import HeaderUser from './header-user.vue'
import HeaderMenusNutritionist from './header-menu-nutritionist.vue'
import HeaderBeranda from './header-beranda.vue'
import HeaderTentangKami from'./header-tentang-kami.vue'
import HeaderProgram from './header-program.vue'
import HeaderBlog from './header-blog.vue'
import HeaderPricing from './header-pricing.vue'
import HeaderLogin from './header-login.vue'
import HeaderHamburger from './header-hamburger.vue'
import PopupMenuNotificationWrapper from './elements/popup-menu-notification-wrapper.vue'
import ButtonIcon from './elements/button-icon.vue'
import BadgeUnread from './elements/badge-unread.vue'
import { ENV } from '@/constants/config'
import UserPoin from '@/components/elements/user-poin.vue'
import { CFlex, CBox, CList, CListItem } from '@chakra-ui/vue'
import HeaderStore from '@/components/header/header-store.vue'
// import HeaderMenus from './header-menus.vue'
// import HeaderStore from './header-store.vue'

export default {
  name: 'HeaderComp',
  components: {
    HeaderStore,
    CFlex,
    CBox,
    CList,
    CListItem,
    UserPoin,
    LogoColored,
    HeaderKeranjang,
    HeaderNotification,
    HeaderUser,
    HeaderMenusNutritionist,
    HeaderBeranda,
    HeaderTentangKami,
    HeaderProgram,
    HeaderBlog,
    HeaderPricing,
    HeaderLogin,
    // HeaderRegister,
    HeaderHamburger,
    ButtonIcon,
    BadgeUnread,
    PopupMenuNotificationWrapper,
    // HeaderMenus,
    // HeaderStore,
  },
  props: {
    backTitle: {
      type: String,
      default: '',
    },
    back: {
      type: [String, Object],
      default: '',
    },
    withBack: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Boolean,
      default: true,
    },
    cart: {
      type: Boolean,
      default: true,
    },
    sideMenu: {
      type: Boolean,
      default: true,
    },
    listMenuProgram: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['toggle'],
  data() {
    return {
      iconCart,
      iconNotification,
      iconHamburger,
    }
  },
  computed: {
    ENV() {
      return ENV
    },
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      headerConfig: 'general/headerConfig',
      getItemsQuantity: 'cart/getItemsQuantity',
      totalPoinUser: 'clients/totalPoinUser',
      showTotalPoinUser: 'clients/showTotalPoinUser',
    }),
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
  },
  methods: {
    handleBack() {
      if (!_.isEmpty(this.back)) {
        this.$router.push(this.back).catch(() => {})
      } else {
        this.$router.back()
      }
    },
  },
}
</script>
